<template>
  <div class="show-calendar-event-images">
        <div class="image-box">
            <img v-if="!images[return_image_number].img_loaded" :src="defualt_photo" alt="תמונה נטענת">
            <img v-show="images[return_image_number].img_loaded" :src="images[return_image_number].image" alt="תמונה לא נטענה" @load="is_img_load(images[return_image_number])">
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons close-icon">close</i>
            </div>
            <div class="nevigate-arrows">
                <button @click="img_number++" class="nvg-btn right">
                    <i class="material-icons arrow-icon">arrow_forward_ios</i>
                </button>
                <button @click="img_number--" class="nvg-btn left">
                    <i class="material-icons arrow-icon">arrow_back_ios</i>
                </button>
            </div>
        <div class="image-details">
            <p>הועלה ע"י {{images[return_image_number].user_name}}</p>
            <p>שייך לסניף: {{images[return_image_number].branche}}</p>
            <p>מס הסניף: {{images[return_image_number].branche_num}}</p>
            <p>נוצר בתאריך: {{display_date_and_time_from_iso(images[return_image_number].date)}}</p>
        </div>
        </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import {display_date_and_time_from_iso} from '../../../../Methods/Dates'
import defualt_photo from '../../../../assets/images/defualt_photo.png'

export default {
    props:['images'],
    emits:['close'],
    setup(props){

        const img_number = ref(0)
        const is_img_load = (event) => {
            event.img_loaded = true
        }
        const return_image_number = ref(computed(() => {
            if(img_number.value >= props.images.length){
                img_number.value = 0
            }else if(img_number.value < 0){
                img_number.value = props.images.length-1
            }
            return img_number.value
        }))       

        return{
            img_number,
            return_image_number,
            display_date_and_time_from_iso,
            is_img_load,
            defualt_photo
        }
    }
}
</script>

<style scoped>
    .show-calendar-event-images{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .image-box{
        position: relative;
        width: 100%;
        max-width: 500px;
        height: 400px;
        background: #fff;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        user-select: none;
    }
    .image-box img{
        max-width: 100%;
        max-height: 90%;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 5px;
        background: var(--blue);
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
    .image-details{
        position: absolute;
        top: -100px;
        width: 100%;
        height: 100px;
        color: #fff;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .nevigate-arrows{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .nvg-btn{
        width: 30px;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border: 0;
        border-radius: 10px 0 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
    .nvg-btn.left{
        border-radius: 0 10px 10px 0;
    }
    .arrow-icon{
        color: #fff;
        font-weight: 500;
    }
</style>